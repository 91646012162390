import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    static: {
      appLogoUrl: '',
      appFaviconUrl: '',
      appMapIframe: '',
      appClinicUrl: '',
      appContacts: '',
      appThemeColor: '',
      appSiteUrl: '',
      nameClient: '',
      nameClientShort: '',
      phoneWork: '',
      addressLegal: '',
      addressActual: '',
      addressPostal: '',
      workingDayBegin: '',
      workingDayEnd: '',
      slides: '',
      opportunities: ''
    }
  },
  mutations: {
    setStatic(state, data){
      state.static = data.data;
    },
  },
  actions: {
    loadStatic({ commit }) {
      axios({
        url: window.config.apiUrl + '/static/site',
        method: 'GET'
      }).then(response => {
        commit('setStatic', {
          data: response.data, 
        })
        setTimeout(() => {
          document.querySelector('body').classList.remove('loading');
        }, 700);
        document.title = response.data.nameClient;
      })
    },
  },
  getters: {
    getStatic: state => state.static,

    
  },
})