<template>
  <div class="section">
    <div class="slider-licensees">
        <h1>Лицензии</h1>
        <div class="splide-licensees">
            <div class="splide__track">
                <ul class="splide__list" id="images">
                    <li class="splide__slide" :key="file.thumb" v-for="file in files">
                      <img :data-original="file.original" :src="file.thumb" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Splide from '@splidejs/splide';
import Viewer from 'viewerjs';

export default {
  data() {
    return {
      files: [
        {
          thumb: 'https://site.cmks.online/files/thumb-1.jpg',
          original: 'https://site.cmks.online/files/1.jpg'
        },
        {
          thumb: 'https://site.cmks.online/files/thumb-1-2.jpg',
          original: 'https://site.cmks.online/files/1-2.jpg'
        },
        {
          thumb: 'https://site.cmks.online/files/thumb-1-3.jpg',
          original: 'https://site.cmks.online/files/1-3.jpg'
        },
        {
          thumb: 'https://site.cmks.online/files/thumb-1-4.jpg',
          original: 'https://site.cmks.online/files/1-4.jpg'
        }
      ]
    }
  },
  mounted(){
    new Splide('.splide-licensees', {
      perPage: 4,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      drag: true,
      interval: 3000,
      speed:  1000
    }).mount();

    var galley = document.getElementById('images');
    new Viewer(galley, {
      url: 'data-original',
      navbar: false,
      toolbar: false,
      tooltip: false,
      title: false
    });
  }
}
</script>

<style scoped>

.slider-licensees{
  padding-bottom: 50px;
}

.splide__slide{
  padding: 10px 40px;
}

.splide-licensees img{
  width: 100%;
  cursor: pointer;
}

@media (max-width: 869px){
  .splide__slide{
    padding: 0 10px;
  }

  .slider-licensees{
    padding-bottom: 25px;
  } 
}

</style>