<template>
  <div id="app">
    <Header/>
    <keep-alive>
      <router-view/>
    </keep-alive>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {mapActions} from 'vuex';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  mounted(){
    this.loadStatic();
  },
  methods: {
    ...mapActions([
      'loadStatic'
    ]),
  }
}
</script>

<style>
  :root {
    --main-bg-color: #f0f5f9;
    --main-color: #62BFFF;
    --main-text-color: #04243D;
  }

  @font-face {
    font-family: 'PT Root UI';
    src: url('assets/fonts/Ptrootui.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'PT Root UI';
    src: url('assets/fonts/Ptrootuimedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'PT Root UI';
    src: url('assets/fonts/Ptrootuibold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('assets/fonts/Gilroylight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('assets/fonts/Gilroyextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  } 

  html{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    color: var(--main-text-color);
  }

  body{
    padding: 0;
    margin: 0;
  }

  body.loading{
    opacity: 0;
  }

  input{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
  }

  /* h1, h2, h3, h4, h5, h6{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
  } */

  a{
    color: var(--main-color);
    text-decoration: none;
  }

  /*** Works on common browsers ***/
  ::selection {
    background-color: var(--main-color);
    color: #fff;
  }

  /*** Mozilla based browsers ***/
  ::-moz-selection {
    background-color: var(--main-color);
    color: #fff;
  }

  /***For Other Browsers ***/
  ::-o-selection {
    background-color: var(--main-color);
    color: #fff;
  }

  ::-ms-selection {
    background-color: var(--main-color);
    color: #fff;
  }

  /*** For Webkit ***/
  ::-webkit-selection {
    background-color: var(--main-color);
    color: #fff;
  }

  .section{
    padding: 0 30px;
    max-width: 1200px;
    margin: auto;
  }

  .button {
    padding: 13px 28px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 30px;
    transition: all .2s ease-in-out;
    display: inline-block;
  }

  .button:hover{
    background-color: #4ea6e3;
  }

  .button.button-outline{
    background: transparent;
    border: 1px solid var(--main-color);
    color: var(--main-color);
  }

  .button.button-outline:hover{
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: #fff;
  }

  @media (max-width: 869px){
    .button{
      padding: 10px 18px;
    }
  }
</style>
