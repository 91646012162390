<template>
    <div class="section">
        <h1>Контакты</h1>
        <div class="contacts">
            <div class="info" v-html="getStatic.appContacts"></div>
            <h1>Как нас найти?</h1>
            <div class="map">
                <div style="position:relative;overflow:hidden;">
                    <iframe :src="getStatic.appMapIframe" height="400" frameborder="0" allowfullscreen="true" style="position:relative; width: 100%"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Contacts',
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>


<style scoped>

.info{
    margin-bottom: 30px;
}
.contacts{
    margin-bottom: 50px;
}

</style>