<template>
  <div>
    <Slider/>
    <div class="section about-section">
        <div>
            <h1>О нас</h1>
            <div v-html="getStatic.siteAbout"></div>
        </div>
    </div>
    <Advantages/>
    <Licensees/>
    <Questions/>
    <Contacts/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Slider from '@/components/Slider.vue'
import Questions from '@/components/Questions.vue'
import Contacts from '@/components/Contacts.vue'
import Licensees from '@/components/Licensees.vue'
import Advantages from '@/components/Advantages.vue'

export default {
  name: 'Home',
  components: {
    Slider,
    Questions,
    Contacts,
    Licensees,
    Advantages
  },
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>
