<template>
  <footer>
        <div class="section">
            <div class="footer">
                <div class="info">
                    <img class="logo" :src="getStatic.appLogoUrl" alt="">
                    <p>{{getStatic.nameClient}}</p>
                    <p>
                        <i class="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; {{getStatic.addressActual}}<br>
                        <i class="fas fa-clock" aria-hidden="true"></i>&nbsp; <span v-html="getStatic.siteWorkSchedule"></span> 
                    </p>
                </div>
                <div>
                    <h4>О нас</h4>
                    <ul class="menu">
                        <li>
                            <router-link to="/">О нас</router-link>
                        </li>
                        <li>
                            <router-link to="/services">Услуги</router-link>
                        </li>
                        <li>
                            <router-link to="/users">Специалисты</router-link>
                        </li>
                        <li>
                            <router-link to="/licensees">Лицензии</router-link>
                        </li>
                        <li>
                            <router-link to="/contacts">Контакты</router-link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>Пациентам</h4>
                    <ul class="menu">
                        <li>
                            <a :href="getStatic.appSiteUrl">Личный кабинет</a>
                        </li>
                        <li>
                            <router-link to="/users">Запись на прием</router-link>
                        </li>
                        <li>
                            <router-link to="/promotions">Акции</router-link>
                        </li>
                    </ul>
                </div>
                <div >
                    <h4>Контакты</h4>
                    <a :href="'tel:' + getStatic.phoneWork" class="footer-phone">
                        <i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp; {{getStatic.phoneWork}}
                    </a>
                    <a :href="'mailto:' + getStatic.companyEmail">
                        <i class="fas fa-envelope"></i>&nbsp; {{getStatic.companyEmail}}
                    </a>
                </div>
            </div>
        </div>
        <div class="footer-bar">
            <div class="section">
                <div>
                    © {{new Date().getFullYear()}} Официальный сайт {{getStatic.nameClientShort}}
                </div>
                <div>
                    Разработано: <a target="_blank" href="http://kiber-soft.net/">ООО «Кибер-Софт»</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>

<style scoped>
footer{
    background-color: #f0f5f9;
}

.footer{
    display: flex;
    padding: 50px 0;
    justify-content: space-between;
}

.footer div{
    padding: 10px;
}

.footer .logo{
    height: 60px;
}

.footer ul.menu{
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer ul.menu li{
   margin-bottom: 5px;
}

footer a{
   color: var(--main-text-color);
   transition: .2s ease-in-out;
}

footer a:hover{
    color: var(--main-color);
}

.footer .footer-phone{
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}

.footer i {
    color: var(--main-color);
}

.footer-bar{
    padding: 20px 0;
    border-top: 1px solid #e3e8eb;
    color: #8393a1;
    font-size: 14px;
}

.footer-bar > .section{
    display: flex;
    justify-content: space-between;
}

@media (max-width: 869px){
    .footer{
        flex-flow:column;
        padding: 25px 0;
    }
    .footer-bar > .section{
          flex-flow:column;
          text-align: center;
    }
}

</style>
