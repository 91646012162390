<template>
  <header>
    <div class="section header">
      <div class="logo-section">
        <router-link to="/">  
          <img class="logo" :src="getStatic.appLogoUrl" alt="">
        </router-link>
      </div>
      <div class="search-bar">
        <!-- <input type="text" placeholder="Поиск по сайту"> -->
      </div>
      <div class="info">
        <i class="fas fa-map-marker-alt"></i>&nbsp; {{getStatic.addressActual}}<br>
        <i class="fas fa-clock"></i>&nbsp; <span v-html="getStatic.siteWorkSchedule"></span> 
      </div>
      <div class="phones-section">
        <a class="phone" :href="'tel:' + getStatic.phoneWork"><i class="fas fa-phone-alt"></i> {{getStatic.phoneWork}}</a><br>
        <a :href="'mailto:' + getStatic.companyEmail">
          <i class="fas fa-envelope"></i>&nbsp; {{getStatic.companyEmail}}
        </a>
      </div>
    </div>
    <div class="section navigation">
      <div class="menu hidden">
        <ul>
          <li>
            <router-link to="/">О нас</router-link>
          </li>
          <li>
            <router-link to="/services">Услуги</router-link>
          </li>
          <li>
            <router-link to="/users">Специалисты</router-link>
          </li>
          <li>
            <router-link to="/licensees">Лицензии</router-link>
          </li>
          <li>
            <router-link to="/promotions">Акции</router-link>
          </li>
          <li>
            <router-link to="/contacts">Контакты</router-link>
          </li>
        </ul>
      </div>
      <div class="lk-actions">
        <a :href="getStatic.appSiteUrl"><i class="fas fa-user"></i>&nbsp; Личный кабинет</a> &nbsp; 
        <router-link to="/users" class="button">Запись на прием</router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>

<style scoped>

.header{
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f5f9;
}

.header a{
  color: var(--main-text-color);
  transition: color .2s ease-in-out;
}

.header a:hover{
  color: var(--main-color);
}

.search-bar{
  width: 100%;
  padding: 9px 20px;
}

.search-bar input{
  background: #f0f5f9;
  border: none;
  padding: 14px 20px;
  border-radius: 50px;
  width: 100%;
  box-sizing: border-box
}

.search-bar input:focus{
  outline: none;
}

.search-bar input::-webkit-input-placeholder { /* Chrome */
  color: #a0aeb9;
}

.search-bar input:-ms-input-placeholder { /* IE 10+ */
  color: #a0aeb9;
}

.search-bar input::-moz-placeholder { /* Firefox 19+ */
  color: #a0aeb9;
  opacity: 1;
}

.search-bar input:-moz-placeholder { /* Firefox 4 - 18 */
  color: #a0aeb9;
  opacity: 1;
}

.header .phone{
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  text-align: right;
}

.navigation{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navigation .menu{
  width: 100%;
  padding: 13px 0;
  user-select: none;
}

.phones-section{
  text-align: right;
  min-width: 200px;
  padding: 10px 0;
}

.lk-actions{
  min-width: 370px;
  text-align: right;
}

.navigation .menu ul{
  margin: 0;
  padding: 0;
}

.navigation .menu li{
  display: inline;
  padding: 5px 40px 5px 0;
}

.navigation .menu li a{
  color: var(--main-text-color);
  transition: color .2s ease-in-out;
}

.navigation .menu li a:hover{
  color: var(--main-color);
}

.header .info{
  font-size: 14px;
  padding: 10px 10px;
  min-width: 300px;
}

.logo-section{
  width: 200px;
  display: flex;
}

.logo-section > a{
  margin: auto;
  margin-left: 0;
}

.logo-section .logo{
  height: 60px;
}

.header i{
  color: var(--main-color);
}


@media (max-width: 869px){

  .menu.hidden{
    display: none;
  }

  .header{
    flex-flow:column;
    padding-top: 10px;
  }

  .phones-section{
    text-align: left;
  }

  .header .info{
    padding: 10px 0px;
  }

  .search-bar{
    display: none;
  }

  .menu{
    position: absolute;
    background: red;
    top: 60px;
    padding: 20px !important;
    left: 0;
    width: 100%;
    z-index: 900;
  }

  .menu ul{
    display: flex;
    flex-direction: column;
  }

  .lk-actions{
    width: 100%;
    max-width: 100%;
    text-align: center;
    flex-direction: column;
    display: flex;
    min-width: unset
  }

}

</style>
