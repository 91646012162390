<template>
    <div class="section">
      <div class="advantages">
          <div :key="opportunity.id" v-for="opportunity in getStatic.opportunities">
              <div class="icon">
                  <i :class="opportunity.icon"></i>
              </div>
              <h2>{{opportunity.title}}</h2>
              <p>{{opportunity.text}}</p>
          </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>


<style scoped>
.advantages{
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 40px 0;
}

.advantages > div{
    flex-basis: 100%;
}

.advantages .icon {
    background: #7fbfeb;
    width: 90px;
    height: 90px;
    display: block;
    border-radius: 50%;
    font-size: 27px;
    color: #ffffff;
    padding: 20px 20px;
    border: 6px solid #cee9fb;
    text-align: center;
    margin: auto;
    box-sizing: border-box;
}

@media (max-width: 869px){
    .advantages{
      flex-flow:column;
    }
}

</style>