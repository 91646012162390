<template>
     <div class="slider">
        <div class="splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide" :key="slide.id" v-for="slide in getStatic.slides">
                        <div class="section">
                            <div class="slider-content" v-html="slide.content"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Splide from '@splidejs/splide';

export default {
    computed: {
        ...mapGetters([
            'getStatic'
        ])
    },
    mounted(){
        setTimeout(() => {
            new Splide('.splide', {
                type   : 'loop',
                perPage: 1,
                arrows: false,
                autoplay: true,
                pauseOnHover: true,
                drag: true,
                interval: 3000,
                speed:  1000
                }).mount();
        }, 300);
    }
}
</script>

<style >

.slider{
    background: var(--main-bg-color);
    margin-bottom: 50px;
}

.slider h1{
	line-height: 1;
}

.splide__pagination__page.is-active {
    background: #62bfff !important;
}

.splide__slide {

}

.slider-content{
   background-image: url('./../assets/images/slide1.jpg');
   padding: 60px 0;
}

.slider-content .button{
	margin-bottom: 5px;
}

.splide__pagination__page {
    background: #04243d8f !important;
}
</style>