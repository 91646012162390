<template>
    <div class="questions-section">
        <div class="section">
            <h1>Есть вопросы?<br>
            Просто позвоните нам!</h1>
            <p>Ответим на любой вопрос, проконсультируем и окажем экспертную помощь!</p>
            <h1>
                <a :href="'tel:' + getStatic.phoneWork" class="questions-phone">
                    <i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp; {{getStatic.phoneWork}}
                </a>
            </h1>
        </div>                
    </div>
</template>

<style scoped>

.questions-section{
    padding: 50px 0;
    background: #f0f5f9;
}

@media (max-width: 869px){
    .questions-section{
        flex-flow:column;
        padding: 25px 0;
    }

    .questions-phone{
        font-size: 25px;
    }
}

</style>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Questions',
  computed: {
    ...mapGetters([
      'getStatic'
    ])
  }
}
</script>
